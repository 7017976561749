<template>
  <v-card>
        <v-card-title class="text-h6 orange--text"
          >Learn more about the Hefty Energy Bag Program<v-icon class="mt-0" color="orange"
            >mdi-lightning-bolt</v-icon
          ></v-card-title
        >
        <v-card-text
          >The energy bag program is supported throughout the Greater Omaha
          Area.<br> You can purchase these bags from most grocery and home improvement stores, to see a
          complete list click
          <a
            href="https://www.hefty.com/products/hefty-energybag/energybag-communities/nebraska/greater-omaha-program"
            >here</a
          >. To see more information about the program visit Hefty's website below.
        </v-card-text>
        <v-card-actions> 
            <v-btn raised class="white--text" href="https://www.hefty.com/products/hefty-energybag/how-energybag-program-works" color="orange">Hefty <v-icon class="mt-0" small>mdi-lightning-bolt</v-icon></v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>